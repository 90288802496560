.main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-width: 100vw;
}

.form-container {
    padding: 10px;
    border: 1px solid #000000;
    border-radius: 5px;
    background-color: #116c3ddb;
    max-width: 350px;
    box-shadow: 3px 3px 5px #010000;
}

.submit-button {
    margin-top: 5px;
}