.status {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin: auto;

  &--statusGreen {
      @extend .status;
      background-color: #00ff00;
  }

  &--statusRed {
      @extend .status;
      background-color: #ff0000;
  }

  &--statusBlue {
      @extend .status;
      background-color: #0095ff;
  }
}
