.footer {
  background-color: black;
  color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  border-style: solid;
  border-color: grey;
  font: 1rem Inconsolata, monospace;
  display: flex;
  border-radius: 1rem;

  .text {
    width: 95%;
    justify-content: left;
    display: grid;
    height: 100%;
    padding: 0.5rem;

    .line {
      min-height: 20px;
    }
  }

  .close {
    width: 5%;
    padding-top: 1%;
    padding-right: 1%;
    display: flex;
    justify-content: right;
    align-items: flex-start;
    height: 100%;
  }

}

.full-screen {
  height: 100%;
}