.table {
  width: 100%;

  .header {
    font-weight: bold;
    position: sticky;
    z-index: 1;
    top: 0px;
    background-color: #2b3035;
  }

  th, td {
    padding: 10px;
  }

}
