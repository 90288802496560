.mainContainer {
    height: 80vh;
    max-width: 100vw;
    padding-top: 10px
}

.dataContainer {
    background-color: #2b3035;
    border-radius: 5px;
    color: white;
    box-shadow: 3px 3px 5px #010000;
    text-align: center;
    height: 90%;
    overflow-y: auto;
    position: sticky;
    top: 0px;
}
